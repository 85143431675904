import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import ProductRetailerTable from "../components/ProductRetailerTable";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Helmet from "react-helmet";

import { IProduct, IProductPrice } from "../@types/globals";
import _ from "underscore";
import * as dayjs from "dayjs";

// const EVENT_ID = "01CZ5GESFFWB2X70RYDYXCEY0R";
const EVENT_ID = "01CX724AQPM7CEYE9JJ7DTDDYK";

const API =
  process.env.NODE_ENV === "production"
    ? "https://api.askhargapedia.com/graphql"
    : "https://api.askhargapedia.com/graphql";

const fetchEvent = async (id: string, retailers?: string[]) => {
  const query = `
  query GetEventDetailsForWeb($id: ID!, $retailers: [ID]) {
    event(id: $id, disable_targeting: true) {
      products {
        id
        short_name
        slug
        image {
          small
        }
        prices(retailers: $retailers){
          id
          retailer {
            id
            name
          }
          price
          currency
          location
          source_type
          modified_at
        }
      }
      retailers {
        id
        name
        logo {
          center
        }
      }
    }
  }
  `;
  const variables = { id, retailers };
  const response = await fetch(API, {
    method: "POST",
    // mode: 'cors',
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "5ba1d496-314f-4d3e-ad0e-4042ef8a49e9",
      "x-application-version": "minisite",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  if (response.ok) {
    const { data, error } = await response.json();
    return { data, error };
  }
  return { data: null, error: Error("No response") };
};

export default function PriceDashboard() {
  const [products, setProducts] = useState([]);
  const [retailers, setRetailers] = useState([]);

  const getLastUpdated = (products: IProduct[]): string => {
    // get the latest date out of all the prices
    const prices: IProductPrice[] = [];
    products.forEach((p) => {
      prices.push(...p.prices);
    });

    const dates = prices.map((p) => dayjs(p.modified_at).unix());
    const final = _.sortBy(dates).reverse();
    if (final && final.length > 0) {
      const date = final[0];
      return `Dikemas kini: ${dayjs.unix(date).format("D MMM YYYY, hh:mmA")}`;
    }
    return "";
  };

  useEffect(() => {
    fetchEvent(
      EVENT_ID,
      retailers.length > 0 ? retailers.map((e) => e.id) : null
    ).then(({ data }) => {
      if (data && data.event) {
        setProducts(data.event.products);
        if (retailers.length === 0) {
          setRetailers(data.event.retailers);
        }
      }
    });
  }, [retailers]);

  if (products.length === 0) {
    return <Loader />;
  }

  return (
    <Layout pageName="price-dashboard">
      <Helmet>
        <title>Price Dashboard</title>
      </Helmet>
      {/* <div className="header-dashboard">
        <h2>Jejak harga bersama</h2>
        <StaticImage
          src="../images/logo-white.svg"
          height={20}
          alt="Hargapedia"
        />
      </div> */}
      <ProductRetailerTable
        cheapestGradient={["#00C853", "#00C853", "#4CAF50"]}
        products={products}
        retailers={retailers}
      />
      <div className="disclaimer">
        <div className="updated">{getLastUpdated(products)}</div>
        <span>Penafian:</span> Semua harga dan maklumat di sini disahkan betul
        pada masa muat naik tetapi tertakluk kepada perubahan tanpa notis
        terlebih dahulu oleh peruncit masing-masing.
      </div>
      {/*   <div className="update-container">
    //     {getLastUpdated(products)}

    //     <a
    //       href="https://www.hargapedia.com.my"
    //       target="_blank"
    //       className="powered-by"
    //     >
    //       <span>Disediakan oleh </span>
    //       <StaticImage
    //         src="../images/logo-white.svg"
    //         alt="Hargapedia"
    //         height={18}
    //       />
    //     </a>
    //   </div> */}
      <div className="cta-container">
        <div className="col-left">
          <h2>
            Guna Hargapedia untuk Banding Harga, Semak Promosi &amp; Dapat
            Baucar!
          </h2>
          <p>Muat turun Hargapedia dari gedung aplikasi anda hari ini!</p>

          <div className="btn-group">
            <OutboundLink
              href="https://itunes.apple.com/us/app/hargapedia/id1315874967?ls=1&mt=8"
              target="_blank"
            >
              <StaticImage
                height={40}
                src="../images/btn-apple.svg"
                alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Apple App Store"
              />
            </OutboundLink>
            <OutboundLink
              href="https://play.google.com/store/apps/details?id=com.askhargapedia.app"
              target="_blank"
            >
              <StaticImage
                height={40}
                src="../images/btn-google.svg"
                alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Google Play Store"
              />
            </OutboundLink>
            <OutboundLink
              href="https://appgallery5.huawei.com/#/app/C102195777"
              target="_blank"
            >
              <StaticImage
                height={40}
                src="../images/btn-huawei.svg"
                alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from Huawei AppGallery"
              />
            </OutboundLink>
          </div>
        </div>

        <div className="col-right">
          <StaticImage
            src="../images/mockup-download.png"
            alt="Hargapedia App"
            height={400}
          />
        </div>
      </div>
    </Layout>
  );
}
